import React from 'react'
import { Layout } from 'antd'
import Menu from 'components/Menu'
import Header from 'components/Header'
import styled from 'styled-components'
import useIsMobile from 'hooks/useIsMobile'
import Sidebar from 'components/Menu/Sidebar'
import SidebarRight from 'components/Menu/SidebarRight'
import SearchBar from 'components/Menu/SearchBar'
import { sidebarLinks } from 'components/Menu/config'
import { InfoPageLayout } from 'views/Info'

const WrapperHeaderTop = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  padding: 6px 15px;
  background: #5868e9;
  text-align: center;
  display: none;

  @media (min-width: 991px) {
    display: block;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`

const mobileWith = 1024
const PublicLayout = ({ children, hasSider = true }) => {
  const isMobile = useIsMobile(mobileWith)
  return (
    <>
      <Header />
      {children}
    </>
  )
}

export default PublicLayout
