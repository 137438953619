import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { WalletType } from '../types'

export interface CollectionState {
  walletInfo?: WalletType
}

const initialState: CollectionState = {
  walletInfo: undefined,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setProfileWalletToState, (state, { payload }) => {
    state.walletInfo = { ...state.walletInfo, ...payload }
  }),
)
