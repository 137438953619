import styled, { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Digitalt';
    src: local('Digitalt'), url('/fonts/Digitalt/Digitalt.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SpaceGrotesk';
    src: local('SpaceGrotesk'), url('/fonts/SpaceGrotesk.ttf') format('truetype');
  }

  /* * {
    font-family: 'Kanit', sans-serif;
  } */
  body {
    /* background-color: ${({ theme }) => theme.colors.background}; */
    font-family: 'Inter', sans-serif;
    /* line-height: 150%; */
    /* color: #FDFDFD; */

    /* .private-layout {
      background: #14151A;
      color: #000000;
    } */

    color: #000000;
    img {
      height: auto;
      /* max-width: 100%; */
    }
  }

  .underline {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    background: linear-gradient(99.46deg, #fa00ff -10.9%, #00e0ff 97.13%, #2ad4f9 97.14%);
    height: 2px;
    width: 100%;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .error {
    font-size: 12px;
    color: #FF4842;
  }
  // ant select dropdown
  .ant-form-item {
    margin: 0;
  }
  .ant-select-dropdown {
    padding: 0; 
    background: #0C131F; 
    border-radius: 4px; 
    overflow: hidden;
  }
  .ant-select-item {
    color: rgb(230 247 255); 
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: rgb(230 247 255); 
    background: #83ccf045; 
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff; 
    background: #83ccf045; 
  }
  .ant-select-arrow {
    img {
      transform: rotate(180deg);
    }
  }
  .ant-select-open .ant-select-arrow {
    img {
      transform: rotate(0deg);
    }
  }
`

export const ButtonStyled = styled.button`
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  /* display: inline-block; */
  &:hover {
    background: #bd4d08;
  }

  &:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: scale(1);
  }
`

export const UpDown = styled.div<any>`
  animation: upAndDown ${({ duration }) => duration || '5s'} infinite;
  --transformX: 0%;
  --transformY: 0%;
`

export default GlobalStyle
