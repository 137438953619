/* eslint-disable @typescript-eslint/no-extra-semi */
import { useEffect, useState, useReducer, useCallback } from 'react'
import { BigNumber } from 'bignumber.js'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { loadWeb3, loadBlockchainData, useCreateTokenContract, useWeb3Contract } from 'hooks/useContract'
import Misc from 'helpers/Misc'
import useInterval from 'hooks/useInterval'
import unserializedTokens from 'config/constants/tokens'
import { calculateBalance } from 'utils'
import ABI_GREEN_HOOKED_TOKEN from 'config/abi/abi_green_hooked.json'
import { MINING_API } from 'config/constants'
import { TokenType } from '../state/types'
import { setProfileWalletToState } from '../state/wallet/actions'

export async function getBalanceByAccount(contract, account, _defaultInfoToken) {
  let defaultInfoToken = _defaultInfoToken
  if (!defaultInfoToken) defaultInfoToken = {}
  return new Promise((resolve, reject) => {
    ;(async () => {
      if (contract) {
        const balance = await contract.balanceOf(account)
        const decimals = await contract.decimals()

        const parseBalance = calculateBalance(balance, decimals)
        resolve({ balance: parseBalance, ...defaultInfoToken })
      } else {
        reject(new Error('Cannot read contract, please try again later.'))
      }
    })()
  })
}
export async function getFullInfoTokenByAccount(contract, account) {
  return new Promise((resolve, reject) => {
    ;(async () => {
      if (contract) {
        await Promise.all([contract.balanceOf(account), contract.symbol(), contract.name(), contract.decimals()])
          .then(([balance, symbol, name, decimals]) => {
            const data = {
              name,
              symbol,
              decimals,
              chainId: 56,
              balance: calculateBalance(balance, decimals),
              address: contract._address,
            }
            resolve(data)
          })
          .catch(reject)
      } else {
        reject(new Error('Cannot read contract, please try again later.'))
      }
    })()
  })
}

export async function getBaseTokenPoint(account) {
  if (account) {
    const rawResponse = await fetch(`${MINING_API}/api/mining/${account}`)

    const res = await rawResponse.json()
    if (res.status === 1) {
      return res.data
    }
  }
  return {}
}

export async function getQ2ERate(contract) {
  return new Promise((resolve, reject) => {
    ;(async () => {
      if (contract) {
        const data = await contract.methods.RATE().call()

        // const decimals = await contract.decimals()

        // const parseBalance = calculateBalance(balance, decimals)
        // resolve({ balance: parseBalance, ...defaultInfoToken })
      } else {
        reject(new Error('Cannot read contract, please try again later.'))
      }
    })()
  })
}

export const getUGHBalance = async (account) => {
  if (account) {
    const rawResponse = await fetch(`${MINING_API}/api/balance/${account}`)

    const res = await rawResponse.json()

    if (res.status === 1) {
      return res.data
    }
  }
  return {}
}

export default function useGetProfileWallet() {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [web3IsActive, setWeb3IsActive] = useState<boolean>(false)
  const BASE_TOKEN = unserializedTokens.base
  const BASE_USDT = unserializedTokens.usdt
  const BASE_BUSD = unserializedTokens.busd
  const BASE_Q2E = unserializedTokens.q2e

  // const greenHookedContract = useWeb3Contract(BASE_TOKEN.address, ABI_GREEN_HOOKED_TOKEN)
  const contractBep20 = useCreateTokenContract(BASE_TOKEN.address)
  const contractTokenUSDT = useCreateTokenContract(BASE_USDT.address)
  const contractTokenBUSD = useCreateTokenContract(BASE_BUSD.address)
  const contractTokenQ2E = useCreateTokenContract(BASE_Q2E.address)

  const getProfileWallet = useCallback(async () => {
    if (!contractBep20?.methods) {
      forceUpdate()
      await Misc.sleep(1000)
    }

    if (account && contractBep20) {
      try {
        const baseTokenPoint = await getBaseTokenPoint(account)
        const ughBalance = await getUGHBalance(account)

        const infoWallet = await loadBlockchainData(account)

        // const baseToken = (await getFullInfoTokenByAccount(contractBep20, account)) as TokenType
        const baseToken = {} as TokenType
        // const tokenUSDT = (await getBalanceByAccount(contractTokenUSDT, account, BASE_USDT)) as TokenType
        // const tokenBUSD = (await getBalanceByAccount(contractTokenBUSD, account, BASE_BUSD)) as TokenType
        const tokenQ2E = (await getBalanceByAccount(contractTokenQ2E, account, BASE_Q2E)) as TokenType
        const newInfoWallet = {
          ...infoWallet,
          balance: new BigNumber(infoWallet.balance).shiftedBy(-18).toNumber(),
          baseToken,
          baseTokenPoint,
          ughBalance,
          assets: [baseToken, tokenQ2E],
        }
        dispatch(setProfileWalletToState(newInfoWallet))
      } catch (e) {
        console.error('E0001', e)
      }
    }
  }, [BASE_Q2E, account, contractBep20, contractTokenQ2E, dispatch])

  /**
   * Init config web3 and interval load balance
   */
  const connectionWeb3 = useCallback(async () => {
    const isWalletAvailable = loadWeb3()
    if (!isWalletAvailable) {
      forceUpdate()
      await Misc.sleep(1000)
    } else {
      setWeb3IsActive(true)
    }
  }, [])
  useEffect(() => {
    if (!web3IsActive) {
      connectionWeb3()
    } else {
      getProfileWallet()
    }
  }, [connectionWeb3, getProfileWallet, web3IsActive])
  // useInterval(async () => {
  //   if (web3IsActive) {
  //     getProfileWallet()
  //   }
  // }, 7000)
  return { getProfileWallet }
}
