import LinearCard from 'components/Card/LinearCard'
import React from 'react'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

const CardWrapper = styled.div`
  /* padding: 1rem; */
  position: relative;
  /* background: linear-gradient(to right, red, purple); */
  /* border: 1px solid; */

  background: linear-gradient(93.88deg, #3df8b5 1.88%, #6ef83d 102.87%);

  padding: 1px;
`

const Content = styled.div`
  background: #000000;
  color: white;
  height: 100%;
`

function HeaderButton({ children, className = '', style = {} }) {
  return (
    <CardWrapper
      className={twMerge(['relative text-white h-[42px] w-[170px] px-2 cursor-pointer', className].join(' '))}
    >
      <Content className="flex items-center justify-center px-4" style={style}>
        {children}
        <div className="absolute -top-1.5 -left-1.5 border-l border-t border-[#4CEC36] w-[10px] h-[10px] border-solid" />
        <div className="absolute -top-1.5 -right-1.5 border-r border-t border-[#4CEC36] w-[10px] h-[10px] border-solid" />
        <div className="absolute -bottom-1.5 -left-1.5 border-l border-b border-[#4CEC36] w-[10px] h-[10px] border-solid" />
        <div className="absolute -bottom-1.5 -right-1.5 border-r border-b border-[#4CEC36] w-[10px] h-[10px] border-solid" />
      </Content>
    </CardWrapper>
  )
}

export default HeaderButton
