import Link from 'components/Link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'
import HeaderButton from './HeaderButton'

const MenuText = styled.p`
  &.linear {
    background: linear-gradient(93.88deg, #3df8b5 1.88%, #6ef83d 102.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`

const LogoText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;

  text-transform: capitalize;

  background: linear-gradient(95.32deg, #3df8b5 3.74%, #6ef83d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

function Header(props) {
  const router = useRouter()
  return (
    <header>
      <div className="flex flex-col items-center pt-4 font-Andale">
        <div className="container">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div className="flex items-center space-x-4">
                <img src="/images/logo.png" alt="" />
                <LogoText className="font-Syne flex">Green Hooked</LogoText>
              </div>
              <div className="flex items-center ml-8">
                {[
                  {
                    title: 'Home',
                    href: '/',
                  },
                  {
                    title: 'Product',
                    href: '/product',
                  },
                  {
                    title: 'Tokenomics',
                    href: '/tokenomics',
                  },
                  {
                    title: 'Roadmap',
                    href: '/roadmap',
                  },
                  {
                    title: 'Community',
                    href: '/community',
                  },
                ].map((item) => {
                  const isActive = router.asPath === item.href
                  return (
                    <Link href={item.href} key={item.href}>
                      <div className="relative">
                        <div className="flex pl-1 pr-8">
                          <MenuText className={['font-Andale', isActive ? 'linear' : 'text-[#6D6D6D]'].join(' ')}>
                            {item.title}
                          </MenuText>
                        </div>
                        {isActive && (
                          <div className="flex items-center mt-0.5 absolute top-full left-0 w-full">
                            <div
                              className="rounded-full w-[9px] h-[9px]"
                              style={{ background: 'linear-gradient(93.88deg, #3DF8B5 1.88%, #6EF83D 102.87%)' }}
                            />
                            <div
                              className="h-[2px] flex-1"
                              style={{
                                background: 'linear-gradient(93.32deg, #3DF8B5 4%, rgba(110, 248, 61, 0) 99.04%)',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <HeaderButton>Whitepaper</HeaderButton>
              <HeaderButton>
                <div className="flex items-center space-x-1 justify-center">
                  <img src="/images/header/green-cash.png" width="39" alt="" />
                  <p className="whitespace-nowrap">
                    <span className="font-medium"> Green</span> Cash
                  </p>
                  <img src="/images/header/right.png" width="16" alt="" />
                </div>
              </HeaderButton>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
