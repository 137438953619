import '../style/globals.scss'
import '../style/animation.css'
import 'antd/dist/antd.css'
import { ResetCSS } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useEffect } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { NextPage } from 'next'
// import useGetBalance from 'hooks/useGetBalance'
import PublicLayout from 'layouts/PublicLayout'
import PrivateLayout from 'layouts/PrivateLayout'
import useGetBalance from 'hooks/useGetBalance'
import { TokenUpdater } from 'state/info/updaters'
import useGetProfileWallet from 'hooks/useGetProfileWallet'
import { useRouter } from 'next/router'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  // usePollBlockNumber()
  useEagerConnect()
  // useUserAgent()
  // useAccountEventListener()
  // useSentryUser()
  useGetProfileWallet()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)
  const title = 'We DxD - Invest for the future'
  const description =
    "WeDxD is a community controlled deflationary token, WeDxD's purpose is to develop solutions that will make the cryptocurrency space safer and easier to understand for everyone."
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="shortcut icon" type="image/png" href="%PUBLIC_URL%/favicon.ico" />

        {/* <!-- Og --> */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={title} />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={title} />
        <meta property="og:image" content="https://wedxd.com/static/images/graph.png" />
        <meta property="og:url" content="https://protocol.wedxd.com" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://protocol.wedxd.com" />
        <meta name="twitter:image" content="https://wedxd.com/static/images/graph.png" />
        <meta name="twitter:creator" content="@DxdLabs" />

        {/* <!-- Title --> */}
        <title>{title}</title>
        <meta name="keywords" content={title} />
        <meta name="description" content={description} />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-T1DFYKZYFC" />
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-T1DFYKZYFC');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  // useGetBalance()
  // Use the layout defined at the page level, if available
  const Layout = Fragment

  const router = useRouter()
  const { ref }: any = router.query

  useEffect(() => {
    const _ref = sessionStorage.getItem('ref')
    if (ref && !_ref) {
      sessionStorage.setItem('ref', ref)
    }
  }, [ref])

  return (
    <ProductionErrorBoundary>
      {/* <Menu> */}
      {/* <Menu /> */}
      {/* <TokenUpdater /> */}

      <Layout>
        {Component.fullLayout ? (
          <PrivateLayout>
            <Component {...pageProps} />
          </PrivateLayout>
        ) : (
          <PublicLayout>
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
      {/* </Menu> */}
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
    </ProductionErrorBoundary>
  )
}

export default MyApp
