import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React from 'react'
import { UpDown } from 'style/Global'
import styled from 'styled-components'

const BackgroundHome = dynamic(() => import('./components/BackgroundHome'), {
  loading: () => null,
})
const ConnectBackground = dynamic(() => import('./components/ConnectBackground'), {
  loading: () => null,
})
const WalletBackground = dynamic(() => import('./components/WalletBackground'), {
  loading: () => null,
})
const MiningBackground = dynamic(() => import('./components/MiningBackground'), {
  loading: () => null,
})
const SwapBackground = dynamic(() => import('./components/SwapBackground'), {
  loading: () => null,
})
const ReferralBackground = dynamic(() => import('./components/ReferralBackground'), {
  loading: () => null,
})
const NFTBackground = dynamic(() => import('./components/NFTBackground'), {
  loading: () => null,
})

const PrivateLayout = ({ children }) => {
  const router = useRouter()
  console.log(router)

  return (
    <div className="private-layout">
      <div className="hidden md:block relative min-h-screen overflow-hidden">
        <img src="/images/common/bg.png" className="absolute bottom-0 left-0 w-full h-full object-cover" alt="" />
        {[
          {
            href: '/',
            comp: BackgroundHome,
          },
          {
            href: '/connect',
            comp: ConnectBackground,
          },
          {
            href: '/wallet',
            comp: WalletBackground,
          },
          {
            href: '/stalking',
            comp: WalletBackground,
          },
          {
            href: '/mining',
            comp: MiningBackground,
          },
          {
            href: '/swap',
            comp: SwapBackground,
          },
          {
            href: '/referral',
            comp: ReferralBackground,
          },
          {
            href: '/nft',
            comp: NFTBackground,
          },
        ].map((item) => {
          const Comp = item.comp
          if (router.asPath !== item.href) return null
          return <Comp />
        })}
        <div className="flex flex-col items-center relative">
          <div className="container pt-8">
            <div className="flex justify-between">
              <div className="flex items-center space-x-2 relative">
                <img src="/images/logo.png" width="140" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative flex items-center flex-col">
            <div className="relative">{children}</div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">{children}</div>
    </div>
  )
}

export default PrivateLayout
