import React, { AnchorHTMLAttributes } from 'react'
import { Link as NavLink } from 'react-router-dom'
import NextLink from 'next/link'

const Link: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, target, ...otherProps }) => {
  const isHttpLink = href?.startsWith('http')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = isHttpLink || !href ? 'a' : NextLink
  const props = isHttpLink ? { href, target } : { href }
  if (isHttpLink) return <Tag {...props} {...otherProps} />
  return (
    <NextLink href={href}>
      <a {...otherProps}>{otherProps.children}</a>
    </NextLink>
  )
}

export default Link
